export const socialsData = {
  github: "https://github.com/federicopoi",
  facebook: "",
  linkedIn: "https://www.linkedin.com/in/federico-poi/",
  instagram: "",
  codepen: "",
  twitter: "",
  reddit: "",
  blogger: "",
  medium: "",
  stackOverflow: "",
  gitlab: "",
  youtube: "",
};
