import catch1 from "../assets/png/catch1.png";
import catch2 from "../assets/png/catch2.png";
import catch3 from "../assets/png/catch3.png";
import catch4 from "../assets/png/catch4.png";
import catch5 from "../assets/png/catch4.png";
import sort1 from "../assets/png/sort1.png";
import sort2 from "../assets/png/sort2.png";
import sort3 from "../assets/png/sort3.png";
import waash1 from "../assets/png/waash1.png";
import scevents1 from "../assets/png/scevents1.png";
import scevents2 from "../assets/png/scevents2.png";
import scevents3 from "../assets/png/scevents3.png";
import scevents4 from "../assets/png/scevents4.png";

import efuSystemImage from "../assets/png/efuSystemImage.png";
export const projectsData = [
  {
    id: 1,
    projectName: "CATCH - Bug Tracker",
    projectDesc:
      "Catch is a software defect tracking system. Commonly known as a bug tracker. Catch is build with Mongo DB, Express JS, React JS, and Node JS. A role based security system was implemented to create permission based roles. The roles of Admin, Project Manager, Team Manager, Developer and Submitter restrict what the user can see and do. The software allows for users to submit tickets and those tickets are tracked through status from Open to Complete.",

    tags: ["Javascript", "MongoDB", "Express JS", "Node JS", "React JS"],
    code: "https://github.com/federicopoi/BUG-TRACKER",
    demo: "https://catch-bug-tracker.herokuapp.com/",
    image: [catch1, catch2, catch3, catch4, catch5],
  },
  {
    id: 2,
    projectName: "SC EVENTS",
    projectDesc:
      "SC EVENTS is a website that allows students to discover and RSVP to events happening on campus, such as club meetings, guest lectures, and social events.",
    tags: [
      "Javascript",
      "MongoDB",
      "Express JS",
      "Node JS",
      "React JS",
      "Taiwind CSS",
    ],
    code: "https://github.com/federicopoi/SC-Events",
    demo: "https://sc-events-d42f93babff2.herokuapp.com/",
    image: [scevents1, scevents2, scevents3, scevents4],
  },
  {
    id: 3,
    projectName: "WAASH - Reminder to Stay Healthy",
    projectDesc:
      "eceive reminders to wash your hands and avoid touching your face! You can customize the notification interval, maintain a daily log, and follow a handwashing tutorial.",
    tags: ["Flutter"],
    code: "https://github.com/federicopoi/",
    demo: "https://play.google.com/store/apps/details?id=com.trif.waash",
    image: [waash1],
  },
  {
    id: 4,
    projectName: "Sorting Visualizer",
    projectDesc:
      "Experience the magic of algorithms in action with my sorting visualizer, a captivating project that demonstrates various sorting techniques through interactive visualizations.",
    tags: ["Javascript", "React JS"],
    code: "https://github.com/federicopoi/Sorting-Visualizer",
    demo: "https://sortingvisualizer2023.netlify.app/",
    image: [sort1, sort2, sort3],
  },

  {
    id: 5,
    projectName: "EFU SYSTEM",
    projectDesc:
      "EFU System is a software which digitize Total Process Management (TPM) tags. EFU System is build with Mongo DB, Express JS, React JS, and Node JS. Big Argentinian process industries such as COFCO, KLOCKNER and PLASTICOS BOULEVARES are using this software in order to reduce the time to manage tags and generate graphics in real time.",
    tags: ["Javascript", "MongoDB", "Express JS", "Node JS", "React JS"],
    code: "https://github.com/federicopoi/EFU-System",
    demo: "https://efu-system-demo-73e4d52d5581.herokuapp.com/login",
    image: [efuSystemImage],
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
