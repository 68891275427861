import resume from "../assets/pdf/resume.pdf";
import image from "../assets/jpeg/image.jpeg";
export const headerData = {
  name: "Federico Poi",
  title: "Full Stack Developer",
  desciption:
    "I am a recent graduate with a degree in Computer Science and minors in Business and Mathematics. I have gained experience in full-stack development through personal projects and internships.",
  image: image,
  resumePdf: resume,
};
